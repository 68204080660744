<template>
  <div class="layout">
    <LeftMenu></LeftMenu>
    <div class="main">
      <TopMenu></TopMenu>
      <div class="router-view">
        <el-scrollbar id="content_scroll" class="menu-scrollbar">
          <keep-alive :include="cachedViews">
            <router-view />
          </keep-alive>
        </el-scrollbar>
      </div>
    </div>
    <AsideImg></AsideImg>
  </div>
</template>

<script>
import LeftMenu from '@/layout/components/LeftMenu.vue';
import TopMenu from '@/layout/components/TopMenu.vue';
import AsideImg from '@/layout/components/AsideImg.vue';
export default {
  name: 'lay-out',
  components: {
    LeftMenu,
    TopMenu,
    AsideImg
  },
  data() {
    return {
      cachedViews: [] // 需要缓存的路由
    };
  }
};
</script>

<style lang="less" scoped>
.layout {
  display: flex;
  height: 100%;
  .main {
    flex: 1;
    background: #f5f5f5;
    overflow: hidden;
    .router-view {
      height: calc(100% - 50px);
      .menu-scrollbar {
        height: 100%;
      }
      /deep/ .el-scrollbar__wrap {
        overflow: auto;
      }
    }
  }
}
</style>
