<template>
  <div>
    <div :class="['aside-img', show ? 'hide' : '']">
      <i class="el-icon-s-custom" @click="toggleShow"></i>
      <div class="text" @click="toggleShow">客服</div>
      <div class="close-icon" @click="toggleShow">
        <div class="txt">
          微信扫一扫添加客服
          <i class="el-icon-close"></i>
        </div>
      </div>
      <img src="@/assets/images/wx.jpg" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'AsideImg',
  data() {
    return {
      show: true
    };
  },
  methods: {
    toggleShow() {
      this.show = !this.show;
    }
  }
};
</script>

<style lang="less" scoped>
.aside-img {
  position: fixed;
  right: 0;
  bottom: 35px;
  z-index: 9;
  text-align: center;
  background-color: #fff;
  box-shadow: 0px 0px 15px 1px #d9d9d9;
  border-radius: 5px;
  padding: 10px;
  .text {
    font-size: 10px;
    margin-top: 3px;
    color: #666;
    cursor: pointer;
    width: 100%;
    display: none;
  }
  .el-icon-s-custom {
    display: none;
  }
  &.hide {
    .close-icon,
    img {
      display: none;
    }
    .text {
      display: block;
    }
    .el-icon-s-custom {
      display: block;
      cursor: pointer;
      color: #07c160;
    }
  }
  .txt {
    font-size: 13px;
    color: #666;
    padding-top: 5px;
  }
  img {
    padding-top: 8px;
    width: 180px;
  }
  .close-icon i {
    position: absolute;
    right: 8px;
    top: 8px;
    cursor: pointer;
  }
}
</style>
