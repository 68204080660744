import Vue from 'vue';
import Vuex from 'vuex';
import getters from './getters';
import createPersistedstate from 'vuex-persistedstate';
Vue.use(Vuex);

// https://webpack.js.org/guides/dependency-management/#requirecontext
const modulesFiles = require.context('./modules', true, /\.js$/);

// you do not need `import app from './modules/app'`
// it will auto require all vuex module from modules file
const vuexKeepSaveArr = [];

const modules = modulesFiles.keys().reduce((modules, modulePath) => {
  // set './app.js' => 'app'
  const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1');
  const value = modulesFiles(modulePath);
  modules[moduleName] = value.default;
  vuexKeepSaveArr.push(moduleName);
  return modules;
}, {});
const store = new Vuex.Store({
  modules,
  getters,
  plugins: [
    // vuex数据持久化
    createPersistedstate({
      paths: vuexKeepSaveArr
    })
  ]
});

export default store;
