<template>
  <div class="top-menu">
    <template v-for="(item, index) in menuList">
      <template v-if="item.title === '商业代理'">
        <el-button
          v-if="$store.getters.loginInfo.is_proxy === 1"
          :class="{ on: defaultActive === item.path }"
          :key="index"
          type="text"
          size="medium"
          @click="goToPage(item)"
        >
          {{ item.title }}
        </el-button>
      </template>
      <el-button
        v-else
        :class="{ on: defaultActive === item.path }"
        :key="index"
        type="text"
        size="medium"
        @click="goToPage(item)"
      >
        {{ item.title }}
      </el-button>
    </template>
  </div>
</template>

<script>
export default {
  name: 'TopMenu',
  data() {
    return {
      menuList: [],
      defaultActive: ''
    };
  },
  watch: {
    $route: function (to) {
      // 路由变化时执行的操作    每次进入页面请求一遍接口
      this.defaultActive = location.pathname;
    }
  },
  created() {
    this.defaultActive = location.pathname;
    const routes = this.$router.options.routes[0].children;
    this.menuList = routes
      .map((item) => {
        const obj = {
          path: item.path,
          title: item.meta.title,
          icon: item.meta.icon,
          img: item.meta.img,
          show: item.meta.show,
          type: item.meta.type,
          children: item.children
        };
        return obj;
      })
      .filter((item) => item.type === 'top');
  },
  methods: {
    goToPage(item) {
      this.$router.push({ path: item.path });
    }
  }
};
</script>

<style lang="less" scoped>
.top-menu {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 50px;
  border-bottom: 1px solid #eee;
  padding: 0 20px;
  background: #fff;
  .el-button--text {
    color: #333;
    font-size: 14px;
    margin: 0 12px;
    &:hover,
    &.on {
      color: #409eff;
    }
  }
}
</style>
