import store from '@/store';
import { get_access_token } from '@/api/index';
export const refreshToken = () => {
  const nowTime = new Date().getTime();
  const expiresTime = sessionStorage.getItem('expiresTime');
  if (!expiresTime) return;
  const { expires_in, account, refresh_token, identity, is_proxy, openid, phone } = store.state.user.loginInfo;
  const passTimeMs = expires_in * 1000 - 20 * 1000; // 提前二十秒更新token
  if (nowTime - expiresTime >= passTimeMs) {
    sessionStorage.setItem('expiresTime', new Date().getTime());
    const params = {
      account,
      refresh_token
    };
    // 更新token
    get_access_token(params).then((res) => {
      const { expires_in, access_token } = res.content;
      const params = {
        expires_in,
        access_token,
        account,
        refresh_token,
        identity,
        is_proxy,
        openid,
        phone
      };
      store.commit('user/setLoginInfo', params);
    });
  }
};
