import request from '@/utils/request';

// 使用封装好的request
export function getInfo(params) {
  return request({
    url: '/user/info',
    method: 'get',
    params
  });
}

// 返回所有的res信息
export function login(data) {
  return request({
    url: '/user/login',
    method: 'post',
    data,
    meta: {
      responseAll: true // 返回所有的信息，包括状态码和message和data
    }
  });
}

// 使用repeatRequest参数让接口 可以同一时间多次调用
export function getList(params) {
  return request({
    url: '/message/list',
    method: 'get',
    params,
    repeatRequest: true // 配置为true，则可以同一时间多次调用
  });
}

// 使用loading
export function getListById(loading) {
  return request({
    url: '/message/listbyId',
    method: 'get',
    ...loading
  });
}

export function gettableList() {
  return request({
    url: '/user/tableData',
    method: 'get'
  });
}

// 注册账号
export const register = (data) => {
  return request({
    url: '/account/register',
    method: 'post',
    data
  });
};
// 获取验证码
export const sendVerifyCode = (data) => {
  return request({
    url: '/guest/send_verify_code',
    method: 'post',
    data
  });
};
// 账号密码登录
export const accountLogin = (data) => {
  return request({
    url: '/account/login/password',
    method: 'post',
    data,
    loading: true
  });
};
// 验证码登录
export const verifyCodeLogin = (data) => {
  return request({
    url: '/account/login/verify_code',
    method: 'post',
    data,
    loading: true
  });
};
// 获取微信登录二维码/wechat/get_qrcode_ticket
export const getQrcodeTicket = (code) => {
  return request({
    url: '/wechat/get_qrcode_ticket',
    method: 'post',
    data: { invite_code: code }
  });
};
// 获取用户扫码状态
export const getQrcodeStatus = (data) => {
  return request({
    url: '/wechat/get_qrcode_status',
    method: 'post',
    data
  });
};
// 绑定微信
export const bindWx = (data) => {
  return request({
    url: '/account/bind_wx',
    method: 'post',
    data
  });
};
// 绑定手机号码/account/bind_phone
export const bindPhone = (data) => {
  return request({
    url: '/account/bind_phone',
    method: 'post',
    data
  });
};
// 扫码微信登录
export const qrcode = (data) => {
  return request({
    url: '/account/login/qrcode',
    method: 'post',
    data
  });
};
// 获取access_token
export const get_access_token = (data) => {
  return request({
    url: '/account/get_access_token',
    method: 'post',
    data
  });
};

// 修改密码
export const modifyPsw = (data) => {
  return request({
    url: '/account/password/modify',
    method: 'post',
    data
  });
};

// 重置密码
export const restPsw = (data) => {
  return request({
    url: '/account/password/reset',
    method: 'post',
    data
  });
};
// 个人中心
export const personalCenter = (data) => {
  return request({
    url: '/account/personal_center',
    method: 'post',
    isAuth: true,
    data
  });
};

// 接口功能：个人中心_订单记录
// 路由：/account/personal_flow

export const personalFlow = (data) => {
  return request({
    url: '/account/personal_flow',
    method: 'post',
    isAuth: true,
    data
  });
};

// 商品中心
// /goods/center
export const goodsCenter = (data) => {
  return request({
    url: '/goods/center',
    method: 'post',
    data
  });
};

// 商品够买
export const goodsBuy = (data) => {
  return request({
    url: '/goods/buy',
    method: 'post',
    isAuth: true,
    data,
    loading: true
  });
};

// 查询够买状态

export const goodsStatus = (data) => {
  return request({
    url: '/goods/status',
    method: 'post',
    isAuth: true,
    data
  });
};

// 接口功能：分享中心
// 路由：/share/upload
export const shareUpload = (data) => {
  return request({
    url: '/share/upload',
    method: 'post',
    isAuth: true,
    data,
    isFile: true,
    loading: true
  });
};

// 口功能：分享记录
// 路由：/share/record

export const shareRecord = (data) => {
  return request({
    url: '/share/record',
    method: 'post',
    isAuth: true,
    data
  });
};

// 接口功能：提交审核
// 仅待审核状态支持提交
// 路由：/share/submit

export const shareSubmit = (data) => {
  return request({
    url: '/share/submit',
    method: 'post',
    data
  });
};

// share/delete

export const shareDelete = (data) => {
  return request({
    url: '/share/delete',
    method: 'post',
    data
  });
};

// 口功能：分享记录
// 路由：/share/update

export const shareUpdate = (data) => {
  return request({
    url: '/share/update',
    method: 'post',
    data,
    isFile: true,
    loading: true
  });
};

// 接口功能：AI润色、纠错、降重
// 路由：/ai_work/polish_form

export const polishForm = (data) => {
  return request({
    url: '/ai_work/polish_form',
    method: 'post',
    isFile: true,
    loading: true,
    data
  });
};
export const polishJson = (data) => {
  return request({
    url: '/ai_work/polish_ai',
    method: 'post',
    loading: true,
    data
  });
};
// 接口功能：商业代理邀请客户信息
// 仅代理账号可见
//
// 路由：/proxy/get_client_infos
export const getClientInfo = (data) => {
  return request({
    url: '/proxy/get_client_infos',
    method: 'post',
    data
  });
};

// 接口功能：商业代理提现信息
// 仅代理账号可见
//
// 路由：/proxy/withdrawal/flow
export const proxyWithdrawal = (data) => {
  return request({
    url: '/proxy/withdrawal/flow',
    method: 'post',
    data
  });
};

// 接口功能：商业代理收款信息更新
// 路由：/proxy/withdrawal/update
// 方法： POST form
export const proxyWithdrawalUpdate = (data) => {
  return request({
    url: '/proxy/withdrawal/update',
    method: 'post',
    isFile: true,
    isAuth: true,
    data
  });
};

// 接口功能：商业代理收款信息获取
// 仅代理账号可见
// 路由：/proxy/withdrawal/info
export const proxyWithdrawalInfo = (data) => {
  return request({
    url: '/proxy/withdrawal/info',
    method: 'post',
    loading: true,
    isAuth: true,
    data
  });
};
// 接口功能：商业代理申请提现
// 仅代理账号可见
// 路由：/proxy/withdrawal/apply

export const proxyWithdrawalApply = (data) => {
  return request({
    url: '/proxy/withdrawal/apply',
    method: 'post',
    loading: true,
    isAuth: true,
    data
  });
};

// 接口功能：获取海报信息
// 路由：/admin/poster/infos
// 方法： POST json
export const posterInfos = (data) => {
  return request({
    url: '/admin/poster/infos',
    method: 'post',
    isAuth: true,
    data
  });
};

// 商业代理提现总览
export const proxyUserinfos = (data) => {
  return request({
    url: '/proxy/withdrawal/brief',
    method: 'post',
    data
  });
};

// 接口功能：获取论文类型
// 路由：/ai_work/write/get_paper_cls

export const getPaperCls = (data) => {
  return request({
    url: '/ai_work/write/get_paper_cls',
    method: 'post',
    isAuth: true,
    data
  });
};

// 接口功能：获取关键词
// 路由：/ai_work/write/get_keywords

export const getKeywords = (data) => {
  return request({
    url: '/ai_work/write/get_keywords',
    method: 'post',
    isAuth: true,
    loading: true,
    loadingText: '正在获取关键词...',
    data
  });
};

// 接口功能：获取参考文献/知识投喂
// 路由：/ai_work/write/get_reference

export const getReference = (data) => {
  return request({
    url: '/ai_work/write/get_reference',
    method: 'post',
    isAuth: true,
    data
  });
};

// 接口功能：获取摘要
// 路由：/ai_work/write/get_abstract

export const getAbstract = (data) => {
  return request({
    url: '/ai_work/write/get_abstract',
    method: 'post',
    isAuth: true,
    loading: true,
    loadingText: '正在生成论文摘要...',
    data
  });
};

// 接口功能：创建AI写作
// 路由：/ai_work/write/create
export const createWrite = (data) => {
  return request({
    url: '/ai_work/write/create',
    method: 'post',
    isAuth: true,
    loading: false,
    loadingText: '正在生成论文提纲...',
    data
  });
};
export const createWriteContent = (data) => {
  return request({
    url: '/ai_work/write/update',
    method: 'post',
    isAuth: true,
    loading: false,
    loadingText: '正在生成论文...',
    data
  });
};
// 接口功能：生成目录结构
// 路由：/ai_work/write/get_structs
export const getStructs = (data) => {
  return request({
    url: '/ai_work/write/get_structs',
    method: 'post',
    isAuth: true,
    loading: true,
    loadingText: '正在生成论文提纲...',
    data
  });
};

// 接口功能：获取AI写作内容
// 路由：/ai_work/write/fetch
export const writeFetch = (data) => {
  return request({
    url: '/ai_work/write/fetch',
    method: 'post',
    isAuth: true,
    loading: true,
    loadingText: '正在获取论文内容...',
    data
  });
};
// 接口功能：解锁论文
// 路由：ai_work/write/unlock
export const unlock = (data) => {
  return request({
    url: 'ai_work/write/unlock',
    method: 'post',
    isAuth: true,
    loading: true,
    data
  });
};
// 接口功能：查询论文状态
// 路由：/ai_work/write/check_status
export const checkStatus = (data) => {
  return request({
    url: '/ai_work/write/check_status',
    method: 'post',
    isAuth: true,
    loading: true,
    data
  });
};
// 接口功能：订单记录
// 路由：/ai_work/records
export const writeRecords = (data) => {
  return request({
    url: '/ai_work/records',
    method: 'post',
    isAuth: true,
    data
  });
};

export const deleteRecord = (data) => {
  return request({
    url: '/ai_work/record/delete',
    method: 'post',
    data
  });
};

// 接口功能：订单详情
// /ai_work/get_details
export const getDetails = (data) => {
  return request({
    url: '/ai_work/get_details_new',
    method: 'post',
    isAuth: true,
    loading: true,
    data
  });
};

// 接口功能：订单记录修改
// 路由：/ai_work/update

export const aiWorkUpdate = (data) => {
  return request({
    url: '/ai_work/update',
    method: 'post',
    isAuth: true,
    data
  });
};

export const get_qrcode_ticket = () => {
  return request({
    url: '/wechat/get_qrcode_ticket/proxy',
    method: 'post',
    isAuth: true
  });
};

// 获取模型列表
export const get_model_list = () => {
  return request({
    url: '/ai_new_work/write/get_model_list',
    method: 'post'
  });
};

// 获取论文类型
export const get_paper_cls = () => {
  return request({
    url: '/ai_new_work/write/get_paper_cls',
    method: 'post'
  });
};

// 生成大纲
export const gen_structs = (data) => {
  return request({
    url: '/ai_new_work/write/gen_structs',
    method: 'post',
    isAuth: true,
    data
  });
};

// 接口功能：上传参考文献.  // 章节和总的AI投喂为同一接口
//   路由：/ai_new_work/write/upload_reference/file
export const upload_reference = (data) => {
  return request({
    url: '/ai_new_work/write/upload_reference/file',
    method: 'post',
    isFile: true,
    isAuth: true,
    data
  });
};

// 接口功能：获取附加服务列表
// 路由：/ai_new_work/write/get_add_services
export const get_add_services = (data) => {
  return request({
    url: '/ai_new_work/write/get_add_services',
    method: 'post',
    isAuth: true,
    data
  });
};

// 接口功能：创建论文订单
// 路由：/ai_new_work/write/create
export const create = (data) => {
  return request({
    url: '/ai_new_work/write/create',
    method: 'post',
    isAuth: true,
    data
  });
};

// 接口功能：论文订单列表
// 路由：/ai_new_work/write/records

export const records = (data) => {
  return request({
    url: '/ai_new_work/write/records',
    method: 'post',
    isAuth: true,
    data
  });
};

// 接口功能：生成支付订单
// 路由：/ai_new_work/write/gen_pay_order
export const gen_pay_order = (data) => {
  return request({
    url: '/ai_new_work/write/gen_pay_order',
    method: 'post',
    isAuth: true,
    loading: true,
    data
  });
};

// 接口功能：今日汇总
// 路由：/ai_new_work/write/today_summary

export const today_summary = (data) => {
  return request({
    url: '/ai_new_work/write/today_summary',
    method: 'post',
    data
  });
};

// 接口功能：获取大纲
// 路由：/ai_new_work/write/fetch

export const fetch = (data) => {
  return request({
    url: '/ai_new_work/write/fetch',
    method: 'post',
    isAuth: true,
    loading: true,
    data
  });
};

// /ai_new_work/write/download_url
export const download_url = (data) => {
  return request({
    url: '/ai_new_work/write/download_url',
    method: 'post',
    isAuth: true,
    loading: true,
    data
  });
};
